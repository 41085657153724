<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>مركز تكنولوجيا المعلومات</h3></div>

    <v-divider></v-divider>
    <v-container>
      <v-row>
        <h4 class="blue--text">
          شركه مياه الشرب والصرف الصحي بأسيوط والوادي الجديد
        </h4>
        <h4>/ المعرض</h4>
        <h4>/ مركز تكنولوجيا المعلومات</h4></v-row
      >
      <v-row justify="center" class="mt-10">
        <v-col
          v-for="(expandImg, i) in ImagesLab"
          :key="i"
          class="pa-1 ma-1"
          xs="8"
          sm="5"
          md="5"
          lg="3"
          cols="8"
        >
          <expandable-image
            class="Labimg scale-in-center"
            :src="expandImg"
               :lazy-src="loadingLink"
             width="300px"
            height="280px"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
          
<script>
export default {
  data() {
    return {
           loadingLink:require("@/assets/Images/load.svg"),
      ImagesLab: [
       require("@/assets/Images/tech_dep/158.jpg"),
       
 
        require("@/assets/Images/tech_dep/152.jpg"),
        require("@/assets/Images/tech_dep/153.jpg"),
        require("@/assets/Images/tech_dep/154.jpg"),
        require("@/assets/Images/tech_dep/155.jpg"),
        require("@/assets/Images/tech_dep/156.jpg"),
     
 
      ],
    };
  },
};
</script>
<style>
.Labimg {
  box-shadow: gray 3px 3px 3px 3px;
}
</style>